




















































import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import '@/validators/vee-validate';
import {EnvProvider} from '@/utilities';
import {i18n} from '@/main';
import { getAppUrl } from '@/utilities/getUrl';

@Component({
  metaInfo() {
    return {
      title: i18n.tc('meta.newPassword.title'),
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  }
})
export default class NewPasswordPage extends Vue {
  private password = '';
  private reEnteredPassword = '';

  private created() {
    if (!this.$route.query.token) {
      this.$router.push('/');
    } else {
      this.$store.dispatch('checkNewPasswordToken', this.$route.query.token)
        .catch(() => window.location.replace(EnvProvider('URL_TENANT')));
    }
  }

  private onSubmit() {
    this.$store.dispatch('confirmAccount', {
      password: this.password,
      reEnteredPassword: this.reEnteredPassword,
      token: this.$route.query.token
    }).then(() => {
      this.$notify({
        group: 'notification',
        type: 'notification-success',
        text: this.$tc('newPassword.success'),
      });
      window.open(getAppUrl('URL_LANDLORD'), '_self');
    })
      .catch((error) => {
        this.$notify({
          group: 'notification',
          type: 'notification-error',
          text: error.response.data.status === 403
            ? this.$tc('newPassword.wrongToken')
            : error.response.data.message,
        });
      });
  }
}
